/* habits.css */

.habit-tracker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
}

.quote-display {
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.2rem;
  color: #333;
  max-width: 80%;
}

.add-habit-container {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.add-habit-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.add-habit-button:hover {
  background-color: #357abd;
}

.habit-grid-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}

.habit-grid {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 20px 0;
  gap: 20px;
}

.habit-grid::-webkit-scrollbar {
  display: none;
}

.habit-card {
  flex: 0 0 calc(25% - 15px);
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.habit-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.habit-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}

.habit-card h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #333;
}

.habit-card p {
  color: #666;
  font-size: 0.9rem;
}

.tags {
  display: flex;
  gap: 8px;
  margin-top: 10px;
}

.tag {
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 12px;
}

.priority {
  background-color: #e3f2fd;
  color: #1565c0;
}

.category {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.habit-card:hover .delete-button {
  opacity: 1;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.nav-button-left {
  left: 10px;
}

.nav-button-right {
  right: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  z-index: 1001;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.habit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-weight: bold;
  color: #333;
}

.input-with-icon {
  display: flex;
  align-items: center;
}

.input-with-icon input {
  flex-grow: 1;
}

.icon-selector {
  margin-left: 10px;
  padding: 5px;
}

.habit-form input,
.habit-form select,
.habit-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.habit-form textarea {
  min-height: 100px;
  resize: vertical;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.form-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
  color: white;
}
.icon-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.icon-button {
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.icon-button:hover {
  background-color: #f0f0f0;
}

.icon-button.selected {
  background-color: #e0e0e0;
  border-color: #999;
}

/* Add these styles to your existing CSS file */
.habit-form .form-group {
  margin-bottom: 16px;
}

.habit-form label {
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

.habit-form input,
.habit-form select,
.habit-form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.habit-form .form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.habit-form .cancel-button,
.habit-form .save-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.habit-form .cancel-button {
  background-color: #f0f0f0;
}

.habit-form .save-button {
  background-color: #4caf50;
  color: white;
}