.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to right, #e0f7fa, #c8e6c9);
  }
  
  .login-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 600px;
    border-radius: 15px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 350px;
  }
  
  .login-container h1 {
    font-size: 32px;
    color: #00796b;
    margin-bottom: 20px;
  }
  
  .login-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-container input {
    padding: 12px;
    margin-bottom: 20px;
    border: 2px solid #80cbc4;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f1f8e9;
    color: #444;
    transition: border-color 0.3s ease;
  }
  
  .login-container input:focus {
    border-color: #4db6ac;
  }
  
  .login-btn {
    padding: 15px;
    background-color: #00897b;
    color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-btn:hover {
    background-color: #4db6ac;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #00796b;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    transition: color 0.3s ease;
  }
  
  .toggle-btn:hover {
    color: #004d40;
  }
  
  @media (max-width: 600px) {
    .login-container {
      width: 90%;
    }
  }
  