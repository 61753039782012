/* Global Settings */
body {
  font-family: 'Pacifico', cursive;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #e0f7fa, #c8e6c9);
  color: #333;
}

/* Navigation Bar */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
 
}



.logo-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: #00897b;
  text-decoration: none;
}


/* Hero Section */
.hero-section {
  text-align: center;
  align-items: relative;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 50px 50px;
  max-width: 1400px;
}

.hero-section h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #00695c;
}

.hero-section p {
  font-size: 22px;
  margin-bottom: 30px;
  color: #444;
}

.cta-button {
  padding: 15px 30px;
  background-color: #00897b;
  color: #fff;
  border-radius: 30px;
  font-size: 18px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #4db6ac;
}

/* Cards Section */
.cards-section {
  display: flex;
  justify-content: space-around;
  padding: 50px 20px;
  background-color: #f1f8e9;
}

.card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 15px;
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #00796b;
}

.card p {
  font-size: 16px;
  color: #666;
}

.card:hover {
  transform: scale(1.05);
}

/* Video Section */
.video-section {
  text-align: center;
  padding: 50px 20px;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 50px;
}

.video-section h2 {
  font-size: 36px;
  color: #00897b;
  margin-bottom: 20px;
}

.video-section p {
  font-size: 20px;
  margin-bottom: 40px;
  color: #444;
}

.videos {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}

video {
  width: 45%;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

/* Footer */
footer {
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
}

footer p {
  font-size: 16px;
  color: #333;
}

/* Soothing Bubble Animation */
.bubble {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: float 20s ease-in-out infinite;
  bottom: -100px;
}

.bubble:nth-child(1) {
  width: 80px;
  height: 80px;
  left: 20%;
  animation-duration: 22s;
}

.bubble:nth-child(2) {
  width: 60px;
  height: 60px;
  left: 50%;
  animation-duration: 18s;
}

.bubble:nth-child(3) {
  width: 100px;
  height: 100px;
  left: 80%;
  animation-duration: 25s;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-1200px) translateX(200px);
  }
}
