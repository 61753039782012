.sidebar {
  width: 60px;
  background-color: var(--sidebar-bg);
  padding: 20px 0;
  transition: all 0.3s ease;
  overflow-x: hidden;
  white-space: nowrap;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 97vh;

}


.sidebar nav {

  flex-grow: 1;
  margin-left: 5 px;
  display: flex;
  flex-direction: column;
}
.spacer {
  flex-grow: 1;
}


.sidebar a {
  display: flex;
  padding: 8px 15px;
  text-decoration: none;
  color: var(--text-color);
}

.menu-item,
.sidebar a {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  text-decoration: none;
  color: var(--text-color);
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.menu-icon {
  width: 20px;
  position: absolute;
  left: 15px;
   margin-right: 15px;

}


.menu-text {
  opacity: 0;

  transition: opacity 0.3s ease;
}




.sidebar:hover .menu-text,
.sidebar.pinned .menu-text {
  opacity: 1;
}

.sidebar:hover,
.sidebar.pinned {
  width: 170px;
}

.product-name {
  display: flex;
  align-items: center;
  padding: 21px 20px;
  font-size: 1.2em;
  font-weight: bold;
}

.flowify-text {
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar:hover .flowify-text,
.sidebar.pinned .flowify-text {
  opacity: 1;
}

.sidebar a:hover,
.sidebar a.active {
  background-color: var(--sidebar-hover);
  border-radius: 5px;
}

.sidebar a.active {
  font-weight: bold;
  color: rgb(1, 1, 14);
}

.sidebar a.active .menu-icon {
  color: rgb(1, 1, 14);
}



.main-content {
  margin-left: 60px;
  transition: margin-left 0.3s ease;
}

.sidebar:hover ~ .main-content,
.sidebar.pinned ~ .main-content,
.sidebar.hovered ~ .main-content {
  margin-left: 170px;
}

@media (max-width: 768px) {
  .sidebar {
    width: 0;
  }
  
  .sidebar:hover,
  .sidebar.pinned,
  .sidebar.hovered {
    width: 170px;
  }

  .main-content {
    margin-left: 0;
  }

  .sidebar:hover ~ .main-content,
  .sidebar.pinned ~ .main-content,
  .sidebar.hovered ~ .main-content {
    margin-left: 170px;
  }
}



.pin-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: var(--text-color);
}

.dark-mode-toggle {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  width: 100%;
  text-align: left;
  font-family: inherit;
}

.dark-mode-toggle i {
  width: 20px;
  text-align: center;
  margin-right: 15px;
}

.dark-mode-toggle-text {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar:hover .dark-mode-toggle-text,
.sidebar.pinned .dark-mode-toggle-text {
  opacity: 1;
}