:root {
  --primary-color: #4CAF50;
  --background-color: #ffffff;
  --text-color: #333333;
  --sidebar-bg: #f0f0f0;
  --sidebar-hover: #e0e0e0;
}

body {
  font-family: 'Pacifico', cursive;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
}

.app {
  display: flex;
  background-color: var(--background-color);
  color: var(--text-color);
}

.main-content {
  flex: 1;
  margin-left: 45px; /* Width of the sidebar */
  padding: 10px 20px;
  transition: all 0.3s ease;
}


hr {
  margin-top: 0;
  margin-bottom: 5px;
}

/* Dark mode styles */
.app.dark-mode {
  --background-color: #333333;
  --text-color: #ffffff;
  --sidebar-bg: #222222;
  --sidebar-hover: #444444;
}