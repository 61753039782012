header {
    font-family: 'Pacifico', cursive;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
  }
  
  .user-profile {
    position: relative;
    cursor: pointer;
  }
  
  .user-profile img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    right: 0;
    background-color: var(--background-color);
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: var(--text-color);
    padding: 12px 12px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    background-color: var(--sidebar-hover);
  }